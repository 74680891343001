import React, { useState, useEffect } from "react";
import Layout from "../components/layout/Layout";
import Link from "../components/kit/Link";
import Container from "../components/kit/Container";
import Hero from "../components/kit/Hero";

const MiniDapps = () => {
  const [selectedDapp, setSelectedDapp] = useState(null);
  const [feed, setFeed] = useState(null);

  useEffect(() => {
    fetch("/data/dapps.json")
      .then((res) => res.json())
      .then((data) => setFeed(data));
  }, []);

  useEffect(() => {
    document.body.style.overflow = selectedDapp ? "hidden" : "auto";
  }, [selectedDapp]);

  const renderUpdates = (updates) => {
    if (!updates) return null;

    try {
      const histroyUpdate = updates;

      if (typeof histroyUpdate === "string") {
        return (
          <>
            <div className="hr h-[1px] bg-contrast3" />
            <div>
              <h5 className="mb-2 text-sm">Updates include</h5>
              <div className="text-grey rounded text-sm">
                <ul className="list-disc ml-5">
                  {histroyUpdate.split(",").map((update) => (
                    <li key={update}>
                      {update.startsWith("-") ? update.slice(1) : update}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </>
        );
      }

      return (
        <>
          <div className="hr h-[1px] bg-contrast3" />
          <div>
            <h5 className="mb-2 text-sm">Updates include</h5>
            <div className="text-grey rounded text-sm">
              <ul className="list-disc ml-5">
                {histroyUpdate.map((update) => (
                  <li key={update}>
                    {update.startsWith("-") ? update.slice(1) : update}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </>
      );
    } catch {
      return null;
    }
  };

  const renderHistory = (history) => {
    if (!history) return null;

    try {
      if (history.length === 0) return null;

      return (
        <>
          <div className="hr h-[1px] bg-contrast3" />
          <div>
            <div className="text-grey rounded text-sm">
              <h5 className="text-white text-sm mb-2">Version history</h5>
              <ul className="flex flex-col gap-4">
                {history.map((history) => (
                  <div key={history.version}>
                    <a href={history.file} download>
                      <h5 className="mb-2 text-lightOrange">
                        {history.version}
                      </h5>
                    </a>
                    <ul className="list-disc ml-5">
                      {typeof history.update === "string" ? (
                        history.update
                          .split(",")
                          .map((update) => (
                            <li key={update}>
                              {update.startsWith("-")
                                ? update.slice(1)
                                : update}
                            </li>
                          ))
                      ) : history.update[0] !== "" ? (
                        history.update.map((update) => (
                          <li key={update}>
                            {update.startsWith("-") ? update.slice(1) : update}
                          </li>
                        ))
                      ) : (
                        <div className="-mt-2" />
                      )}
                    </ul>
                  </div>
                ))}
              </ul>
            </div>
          </div>
        </>
      );
    } catch {
      return null;
    }
  };

  return (
    <>
      <main className="main-wrapper">
        <Layout>
          <div
            className={`fixed z-50 top-0 left-0 w-full h-full overflow-y-auto flex items-start lg:items-center justify-center ${selectedDapp ? "" : "pointer-events-none"}`}
          >
            <div
              className={`relative z-[60] mx-4 mt-8 mb-14 px-6 md:px-10 pt-20 pb-10 bg-contrast1 rounded-lg w-full max-w-[648px] transition-all duration-150 ${selectedDapp ? "opacity-100 translate-y-0" : "opacity-0 translate-y-3"}`}
            >
              <div
                onClick={() => setSelectedDapp(null)}
                className="absolute cursor-pointer right-8 top-8 hover:text-grey transition-all duration-150"
              >
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.39945 13.6537L0.345703 12.5999L5.9457 6.99994L0.345703 1.39994L1.39945 0.346191L6.99945 5.94619L12.5995 0.346191L13.6532 1.39994L8.0532 6.99994L13.6532 12.5999L12.5995 13.6537L6.99945 8.05369L1.39945 13.6537Z"
                    fill="currentColor"
                  />
                </svg>
              </div>
              <div className="flex h-full items-center gap-6 bg-contrast2 p-6 w-full mb-8">
                {selectedDapp && (
                  <>
                    <div className="h-[96px] w-[96px]">
                      <img
                        src={selectedDapp?.icon ?? ""}
                        alt={selectedDapp?.name ?? ""}
                        width={96}
                        height={96}
                      />
                    </div>
                    <div>
                      <div className="mt-0.5 mb-1 text-lg xl:text-[22px]">
                        {selectedDapp?.name}
                      </div>
                      <div className="mb-1 text-sm">
                        {selectedDapp?.description}
                      </div>
                      <p className="mb-1.5 text-sm text-grey lg:text-xs xl:mt-1.5">
                        Updated: {selectedDapp?.date} - v{selectedDapp?.version}
                      </p>
                    </div>
                    <div className="flex h-full flex-1 items-center justify-end xl:pr-4">
                      <div>
                        <Link href={selectedDapp?.file ?? ""} download>
                          Get
                        </Link>
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div className="flex flex-col h-full gap-6">
                <div>
                  <h5 className="mb-2 text-sm">What is {selectedDapp?.name}</h5>
                  <div className="text-grey rounded text-sm">
                    {selectedDapp?.about}
                  </div>
                </div>
                {renderUpdates(selectedDapp?.update)}
                {renderHistory(selectedDapp?.history)}
              </div>
            </div>
            <div
              className={`backdrop-blur-lg fixed z-50 bg-black/50 h-screen w-full top-0 left-0 transition-opacity duration-150 ${selectedDapp ? "opacity-100" : "opacity-0"}`}
            ></div>
          </div>
          <main className="bg-black">
            <Hero
              title={
                <h1>
                  Official Minima
                  <br /> MiniDapps
                </h1>
              }
              maxHeight="lg"
              image="https://storage.googleapis.com/minima-cdn/minidapps/hero_v3.jpg"
              primaryButton={{
                label: "Run a node",
                href: "https://docs.minima.global/docs/runanode/get_started",
                external: true,
              }}
              secondaryButton={{
                label: "Learn more",
                href: "https://minima.global/minidapps",
                external: true,
              }}
            />
            <Container>
              <section className="py-[80px]">
                <div className="pb-10 lg:pb-20">
                  <h3 className="mb-4 text-2xl lg:text-[32px] pb-10">
                    Downloads
                  </h3>
                  <div className="text-grey60 text-lg flex flex-col gap-3">
                    <p>
                      To use MiniDapps you must be running a Minima node on
                      Android, desktop or server.
                    </p>
                    <p>
                      Once downloaded, MiniDapps can be installed onto your node
                      by clicking the + icon on your Home screen.
                    </p>
                    <p>
                      MiniDapp terms of use can be found{" "}
                      <a
                        href="https://docs.minima.global/docs/terms/minidappterms"
                        target="_blank"
                        rel="noreferrer"
                        className="text-lightOrange hover:text-lighterOrange transition-all duration-150"
                      >
                        here
                      </a>
                      .
                    </p>
                  </div>
                </div>
                <div className="grid grid-cols-12 gap-4">
                  {feed &&
                    feed.dapps.map((dapp) => (
                      <div
                        key={dapp.name}
                        onClick={() => setSelectedDapp(dapp)}
                        className="col-span-12 md:col-span-6 lg:col-span-6 xl:col-span-4 2xl:col-span-3"
                      >
                        <div className="bg-contrast1 hover:bg-contrast2 hover:border-contrast3 border border-transparent transition-all cursor-pointer duration-200 flex flex-col items-center justify-center rounded py-6 px-8 h-full w-full">
                          <div className="flex gap-4 w-full mb-2">
                            <img
                              src={dapp.icon}
                              alt={dapp.name}
                              width={64}
                              height={64}
                              className="mb-4"
                            />
                            <div className="-mt-0.5">
                              <h5 className="mb-1">{dapp.name}</h5>
                              <div className="text-grey80 text-sm">
                                {dapp.description}
                              </div>
                            </div>
                          </div>
                          <div className="grid grid-cols-2 w-full">
                            <div className="col-span-1">
                              <p className="text-sm text-grey lg:text-xs xl:mt-1.5">
                                v{dapp.version} - {dapp.date}
                              </p>
                            </div>
                            <div className="col-span-1 flex justify-end">
                              <Link
                                href={dapp.file}
                                download
                                onClick={(evt) => evt.stopPropagation()}
                              >
                                Get
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </section>
            </Container>
          </main>
        </Layout>
      </main>
    </>
  );
};

export default MiniDapps;
